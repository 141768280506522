@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/*** Global ***/
.contact-section .form .btn-floating {
  float: right;
  position: relative;
  bottom: 3rem;
  margin-right: 0;
}
.contact-section .contact {
  border-radius: 0 0.25rem 0.25rem 0;
  background-color: #103f6d;
}
.contact-section .contact .fas,
.contact-section .contact .fab,
.contact-section .contact .far {
  color: #6b89a5;
}

.magazine-section .single-news {
  border-bottom: 1px solid #e0e0e0;
}
.magazine-section .single-news:last-of-type {
  border-bottom: none;
}

.pricing-card {
  text-align: center;
}
.pricing-card ul {
  list-style-type: none;
  padding: 0;
}
.pricing-card .header {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pricing-card .option {
  padding: 2.5rem;
  margin-bottom: 0;
  font-weight: 500;
}
.pricing-card .price {
  position: relative;
  padding-top: 3.94rem;
}
.pricing-card .price .number {
  font-size: 5rem;
  padding: 1.5rem;
  font-weight: 300;
}
.pricing-card .price .number:before {
  content: "$";
  position: absolute;
  font-size: 1.88rem;
  margin-left: -1.2rem;
  margin-top: 0.7rem;
}
.pricing-card .price .number:after {
  content: "/mo";
  position: absolute;
  font-size: 1.88rem;
  margin-top: 3rem;
}
.pricing-card .price .version {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem 0.25rem 0 0;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
}
.pricing-card .striped {
  padding: 1rem;
}
.pricing-card .striped li {
  border-bottom: 1px solid rgba(238, 238, 238, 0.298039);
  margin-bottom: 1rem;
}
.pricing-card .striped.green-striped li {
  border-color: #33c28a;
}
.pricing-card .striped.orange-striped li {
  border-color: #e97d63;
}
.pricing-card .striped.purple-striped li {
  border-color: #963c94;
}
.pricing-card .striped.darker-striped li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.298039);
}
.pricing-card .card-background {
  background-color: #28283f;
  border-radius: 0 0 0.25rem 0.25rem;
}

.card .card-circle {
  border: 2px solid #e0e0e0;
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
}
.card .card-circle .fas,
.card .card-circle .fab,
.card .card-circle .far {
  font-size: 4rem;
}

.mdb-feed .news {
  display: flex;
}
.mdb-feed .news .label {
  display: block;
  flex: 0 0 auto;
  align-self: stretch;
  width: 2.5rem;
}
.mdb-feed .news .label img {
  width: 100%;
  height: auto;
}
.mdb-feed .news .excerpt {
  display: block;
  flex: 1 1 auto;
  align-self: stretch;
  word-wrap: break-word;
  margin: 0 0 1.2rem 1.2rem;
}
.mdb-feed .news .excerpt .brief {
  padding-bottom: 0.5rem;
  font-weight: 500;
}
.mdb-feed .news .excerpt .brief a {
  color: #4285f4;
}
.mdb-feed .news .excerpt .brief .name {
  display: inline-block;
  vertical-align: baseline;
}
.mdb-feed .news .excerpt .brief .date {
  display: inline-block;
  float: none;
  padding-left: 0.7rem;
  font-weight: 300;
  font-size: 0.86rem;
  color: #9e9e9e;
}
.mdb-feed .news .excerpt .added-images {
  margin-bottom: 0.6rem;
}
.mdb-feed .news .excerpt .added-images img {
  display: inline-block;
  margin-right: 0.3rem;
  width: 7rem;
}
.mdb-feed .news .excerpt .added-text {
  margin-bottom: 0.6rem;
  max-width: 450px;
}
.mdb-feed .news .excerpt .feed-footer .like {
  font-weight: 300;
  font-size: 0.86rem;
  color: #9e9e9e;
}
.mdb-feed .news .excerpt .feed-footer .like:hover .fas,
.mdb-feed .news .excerpt .feed-footer .like:hover .fab,
.mdb-feed .news .excerpt .feed-footer .like:hover .far {
  color: #f44336;
  transition: 0.4s;
}
.mdb-feed .news .excerpt .feed-footer .like:hover span {
  color: #000;
  transition: 0.4s;
}
.mdb-feed .news .excerpt .feed-footer .like .fas,
.mdb-feed .news .excerpt .feed-footer .like .fab,
.mdb-feed .news .excerpt .feed-footer .like .far {
  padding-right: 0.5rem;
}
.mdb-feed .news .excerpt .feed-footer span a {
  padding-right: 0.3rem;
  color: #4285f4;
  cursor: default;
}
.mdb-feed .news .excerpt .feed-footer .thumbs .fas,
.mdb-feed .news .excerpt .feed-footer .thumbs .fab,
.mdb-feed .news .excerpt .feed-footer .thumbs .far {
  color: #9e9e9e;
  transition: 0.4s;
}
.mdb-feed .news .excerpt .feed-footer .thumbs .fas:hover,
.mdb-feed .news .excerpt .feed-footer .thumbs .fab:hover,
.mdb-feed .news .excerpt .feed-footer .thumbs .far:hover {
  color: #757575;
}
.mdb-feed .news .excerpt .feed-footer .thumbs .fa-thumbs-up {
  padding-right: 0.3rem;
}
.mdb-feed .news .excerpt .feed-footer .comment {
  color: #9e9e9e;
}

.card-personal .card-body .card-title {
  font-weight: 400;
}
.card-personal .card-body .card-title.title-one {
  transition: 0.4s;
}
.card-personal .card-body .card-title.title-one:hover {
  color: #4285f4;
}
.card-personal .card-body .card-title {
  margin-bottom: 0.3rem;
}
.card-personal .card-body .card-meta {
  font-weight: 300;
  font-size: 0.86rem;
  color: #757575;
}
.card-personal .card-body .card-meta .fas,
.card-personal .card-body .card-meta .fab,
.card-personal .card-body .card-meta .far {
  padding-right: 0.5rem;
}
.card-personal .card-body span {
  transition: 0.5s;
}
.card-personal .card-body span:hover {
  color: #4285f4;
}

.news-card .content .right-side-meta {
  float: right;
  font-weight: 300;
  color: #9e9e9e;
  margin-top: 0.3rem;
}
.news-card .content .avatar-img {
  width: 2rem;
  margin-right: 1rem;
}
.news-card .content img {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.news-card .social-meta .fas,
.news-card .social-meta .fab,
.news-card .social-meta .far {
  padding-right: 0.6rem;
}
.news-card .social-meta .fa-heart-o {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  transition: 0.53s;
}
.news-card .social-meta .fa-heart-o:hover {
  color: #f44336;
}
.news-card .social-meta span {
  float: right;
}
.news-card .md-form {
  margin-top: 1.3rem;
  margin-bottom: 0.3rem;
}
.news-card .md-form .prefix {
  font-size: 1.5rem;
  margin-left: 0.2rem;
  color: rgba(0, 0, 0, 0.4);
}
.news-card .md-form .prefix ~ input,
.news-card .md-form .prefix .md-form .prefix ~ textarea {
  width: calc(100% - 4rem);
}
.news-card .md-form .prefix.active {
  color: rgba(0, 0, 0, 0.4);
}
.news-card .md-form .form-control {
  padding-top: 0;
  padding-bottom: 0.5rem;
  margin-left: 3rem;
  margin-bottom: 0;
  height: 1.3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

section.team-section .avatar img {
  max-width: 200px;
  height: auto;
}
section.team-section .avatar img.rounded-circle {
  max-width: 150px;
}

.fixed-sn .double-nav,
.fixed-sn main,
.fixed-sn footer {
  padding-left: 15rem;
}
.fixed-sn main {
  padding-top: 5.5rem;
}
@media (max-width: 1440px) {
  .fixed-sn .double-nav,
.fixed-sn main,
.fixed-sn footer {
    padding-left: 0;
  }
}
@media (min-width: 600px) {
  .fixed-sn main,
.fixed-sn .page-footer .container-fluid {
    margin-left: 2%;
    margin-right: 2%;
  }
}
@media (min-width: 992px) {
  .fixed-sn main,
.fixed-sn .page-footer .container-fluid {
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (min-width: 1200px) {
  .fixed-sn main,
.fixed-sn .page-footer .container-fluid {
    margin-left: 6%;
    margin-right: 6%;
  }
}

.hidden-sn main {
  padding-top: 5.5rem;
}
.hidden-sn .button-collapse {
  display: block;
  position: relative;
  font-size: 1.4rem;
  margin-right: 0.625rem;
  margin-left: -0.375rem;
  padding-left: 0;
}

.testimonial-carousel .carousel-control {
  background-image: none;
  top: 50%;
  transform: translateY(-35%);
}
.testimonial-carousel .carousel-control:before {
  font-size: 5rem;
  color: rgba(0, 0, 0, 0.6);
  transition: 0.4s;
}
.testimonial-carousel .carousel-control:hover:before {
  color: #000;
  transition: 0.4s;
}
.testimonial-carousel .carousel-control.left {
  left: 6%;
}
.testimonial-carousel .carousel-control.left:before {
  content: "‹";
}
.testimonial-carousel .carousel-control.right {
  right: 6%;
}
.testimonial-carousel .carousel-control.right:before {
  content: "›";
}
.testimonial-carousel .testimonial {
  margin-right: 23%;
  margin-left: 23%;
}
.testimonial-carousel .testimonial .avatar img {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  max-width: 170px;
}

.comments-list .avatar,
.reply-form .avatar {
  max-width: 100px;
}

.fas.fa-reply {
  margin-left: 0.5rem;
}

.chat-room .friend-list li {
  border-bottom: 1px solid #e0e0e0;
}
.chat-room .friend-list li:last-of-type {
  border-bottom: none;
}
.chat-room img.avatar {
  height: 3rem;
  width: 3rem;
}
.chat-room .text-small {
  font-size: 0.95rem;
}
.chat-room .text-smaller {
  font-size: 0.75rem;
}
.chat-room.small-chat {
  position: fixed;
  bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 20rem;
}
.chat-room.small-chat.slim {
  height: 3rem;
}
.chat-room.small-chat.slim .icons .feature {
  display: none;
}
.chat-room.small-chat.slim .my-custom-scrollbar {
  display: none;
}
.chat-room.small-chat.slim .card-footer {
  display: none;
}
.chat-room.small-chat .profile-photo img.avatar {
  height: 2rem;
  width: 2rem;
}
.chat-room.small-chat .profile-photo .state {
  position: relative;
  display: block;
  background-color: #007e33;
  height: 0.65rem;
  width: 0.65rem;
  z-index: 2;
  margin-left: 1.35rem;
  left: auto;
  top: -0.5rem;
  border-radius: 50%;
  border: 0.1rem solid #fff;
}
.chat-room.small-chat .profile-photo.message-photo {
  margin-top: 2.7rem;
}
.chat-room.small-chat .heading {
  height: 2.1rem;
}
.chat-room.small-chat .heading .data {
  line-height: 1.5;
}
.chat-room.small-chat .heading .data .name {
  font-size: 0.8rem;
}
.chat-room.small-chat .heading .data .activity {
  font-size: 0.75rem;
}
.chat-room.small-chat .icons {
  padding-top: 0.45rem;
}
.chat-room.small-chat .my-custom-scrollbar {
  position: relative;
  height: 18rem;
  overflow: auto;
}
.chat-room.small-chat .my-custom-scrollbar > .card-body {
  height: 18rem;
}
.chat-room.small-chat .my-custom-scrollbar > .card-body .chat-message .media img {
  width: 3rem;
}
.chat-room.small-chat .my-custom-scrollbar > .card-body .chat-message .media .media-body p {
  font-size: 0.7rem;
}
.chat-room.small-chat .my-custom-scrollbar > .card-body .chat-message .message-text {
  margin-left: 2.47rem;
}
.chat-room.small-chat .card-footer .form-control {
  border: none;
  padding: 0.375rem 0 0.43rem 0;
  font-size: 0.9rem;
}
.chat-room.small-chat .card-footer .form-control:focus {
  box-shadow: none;
}

.collection-card .stripe {
  position: absolute;
  bottom: 3rem;
  width: 100%;
  text-align: center;
  padding: 1.2rem;
}
.collection-card .stripe.dark {
  background-color: rgba(0, 0, 0, 0.7);
}
.collection-card .stripe.dark a p {
  color: #eeeeee;
}
.collection-card .stripe.light {
  background-color: rgba(255, 255, 255, 0.7);
}
.collection-card .stripe.light a p {
  color: #424242;
}
.collection-card .stripe a p {
  padding: 0;
  margin: 0;
  letter-spacing: 0.25rem;
}

.rating {
  color: #ffa000;
  list-style-type: none;
  padding: 0;
}
.rating li {
  display: inline-block;
}

.cart-modal .table,
.cart-modal th {
  text-align: center;
}
.cart-modal .table .total {
  text-transform: uppercase;
  font-weight: 600;
}
.cart-modal .table .fa-times {
  color: #4285f4;
}

.card.card-ecommerce .card-footer {
  background-color: transparent;
}
.card.card-ecommerce .card-footer .discount {
  color: #9e9e9e;
}
.card.card-ecommerce .card-footer a {
  color: #757575;
  transition: 0.4s;
}
.card.card-ecommerce .card-footer a:hover {
  color: #d50000;
  transition: 0.4s;
}
.card.card-ecommerce .card-footer a.active {
  color: #d50000;
}

.product-table .btn.btn-sm {
  padding: 0.3rem 0.6rem;
}

/* Materializecss Stepper - By Kinark 2016
// https://github.com/Kinark/Materialize-stepper
// CSS v2.1.3
*/
/*Validate.js FIX*/
label.invalid {
  font-size: 12.8px;
  font-size: 0.8rem;
  font-weight: 500;
  color: red !important;
  top: 50px !important;
}
label.invalid.active {
  transform: translateY(0%) !important;
}

/*Validate.js FIX*/
ul.stepper .wait-feedback {
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
ul.stepper .step {
  position: relative;
  list-style: none;
}
ul.stepper .step.feedbacking .step-new-content > *:not(.wait-feedback) {
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}
ul.stepper .step:not(:last-of-type).active {
  margin-bottom: 2.25rem;
}
ul.stepper .step:before {
  position: absolute;
  top: 0.75rem;
  counter-increment: section;
  content: counter(section);
  height: 1.75rem;
  width: 1.75rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  text-align: center;
  line-height: 1.75rem;
  font-weight: 400;
}
ul.stepper .step.active:before {
  background-color: #4285f4;
}
ul.stepper .step.done:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
  font-size: 1rem;
  font-weight: 900;
  background-color: #00c851;
}
ul.stepper .step.wrong:before {
  content: "\f071";
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
  font-size: 1.1rem;
  font-weight: 900;
  background-color: #ff3547;
}
ul.stepper > li:not(:last-of-type) {
  margin-bottom: 0.625rem;
  transition: margin-bottom 0.4s;
}
ul.stepper .step-title {
  margin: 0 -1.3rem;
  cursor: pointer;
  padding: 0.9688rem 2.75rem 1.5rem 4rem;
  display: block;
}
ul.stepper .step-title:after {
  content: attr(data-step-label);
  display: block;
  position: absolute;
  font-size: 0.8rem;
  color: #424242;
  font-weight: 400;
}
ul.stepper .step-title:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
ul.stepper .step.active .step-title {
  font-weight: 500;
}
ul.stepper .step-new-content {
  position: relative;
  display: none;
  height: calc(100% - 132px);
  width: inherit;
  overflow: visible;
  margin-left: 41px;
  margin-right: 24px;
}
ul.stepper > .step:not(:last-of-type):after {
  content: "";
  position: absolute;
  top: 3.125rem;
  left: 0.8438rem;
  width: 0.0625rem;
  height: 40%;
  height: calc(100% - 38px);
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}
ul.stepper > .step.active:not(:last-child):after {
  height: 93%;
  height: calc(100% - 12px);
}
ul.stepper > .step[data-last=true] {
  margin-bottom: 0;
}
ul.stepper > .step[data-last=true]:after {
  height: 0;
  width: 0;
}
ul.stepper .step-actions {
  display: -webkit-box;
  -webkit-box-pack: start;
}
ul.stepper .step-actions .btn:not(:last-child),
ul.stepper .step-actions .btn-flat:not(:last-child),
ul.stepper .step-actions .btn-large:not(:last-child) {
  margin-right: 0.3125rem;
}
ul.stepper .step-new-content .row {
  margin-bottom: 0.4375rem;
}
ul.stepper .md-form label {
  left: 0.875rem;
}
ul.stepper .md-form .validate {
  margin-bottom: 0;
}

@media only screen and (min-width: 993px) {
  ul.stepper.horizontal {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: 20rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow: hidden;
  }
  ul.stepper.horizontal:before {
    content: "";
    background-color: transparent;
    width: 100%;
    min-height: 5.25rem;
    position: absolute;
    left: -3px;
    border-top-left-radius: 2px;
  }
  ul.stepper.horizontal:first-child {
    margin-top: -2.7rem;
  }
  ul.stepper.horizontal .step {
    position: static;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    height: 5.25rem !important;
  }
  ul.stepper.horizontal .step:not(:last-of-type):after {
    content: "";
    position: static;
    display: inline-block;
    width: 100%;
    height: 0.0625rem;
  }
  ul.stepper.horizontal > .step:last-of-type, ul.stepper.horizontal > .step[data-last=true] {
    width: auto !important;
  }
  ul.stepper.horizontal > .step.active:not(:last-of-type):after {
    content: "";
    position: static;
    display: inline-block;
    width: 100%;
    height: 0.0625rem;
  }
  ul.stepper.horizontal .step.active .step-title:before {
    background-color: #4285f4;
  }
  ul.stepper.horizontal .step.done .step-title:before {
    font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f00c";
    font-size: 1rem;
    background: #00c851;
  }
  ul.stepper.horizontal .step.wrong .step-title:before {
    font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f071";
    font-size: 1.1rem;
    background-color: #ff3547;
  }
  ul.stepper.horizontal .step-title {
    line-height: 5.25rem;
    height: 5.25rem;
    margin: 0;
    padding: 0 1.5625rem 0 4.0625rem;
    display: inline-block;
    max-width: 13.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  ul.stepper.horizontal .step:before {
    content: none;
  }
  ul.stepper.horizontal .step .step-title:before {
    position: absolute;
    top: 1.7813rem;
    left: 1.1875rem;
    counter-increment: section;
    content: counter(section);
    height: 1.75rem;
    width: 1.75rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    text-align: center;
    line-height: 1.75rem;
    font-weight: 400;
  }
  ul.stepper.horizontal .step-title:after {
    top: 0.9375rem;
  }
  ul.stepper.horizontal .step-new-content {
    position: absolute;
    height: calc(100% - 84px);
    top: 6rem;
    left: 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 1.25rem 1.25rem 4.75rem 1.25rem;
  }
  ul.stepper.horizontal .step-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    flex-direction: row-reverse;
  }
  ul.stepper.horizontal .step-actions .btn:not(:last-child),
ul.stepper.horizontal .step-actions .btn-flat:not(:last-child),
ul.stepper.horizontal .step-actions .btn-large:not(:last-child) {
    margin-left: 0.3125rem;
    margin-right: 0;
  }

  ul.stepper.horizontal .step-new-content,
ul.stepper.horizontal .step-actions {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li a {
  padding: 0px 24px;
  left: 50%;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li a {
    left: 6%;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li a {
    left: 6%;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li a .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -50px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.stepper-vertical.timeline li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content {
    width: 80%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content {
    width: 85%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li .step-content:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li .step-content:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 3px;
  background-color: #e0e0e0;
  left: 50%;
  top: 65px;
  margin-left: -1.5px;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after {
    left: 6%;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after {
    left: 6%;
  }
}
@media (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li {
    align-items: flex-end;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before {
  top: 1rem;
  border-left: 15px solid #d32f2f;
  border-right: 0 solid #d32f2f;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after {
  top: 1rem;
  border-left: 14px solid #d32f2f;
  border-right: 0 solid #d32f2f;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content .timeline-header {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li:not(:last-child):after {
  background-color: #d32f2f;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-animated .more-padding {
  padding-right: 100px;
  padding-left: 100px;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-animated li {
  transition: all 0.7s ease-in-out;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li a {
  padding: 0px;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li a .circle {
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -12px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content {
    width: 80%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content {
    width: 85%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:before {
  position: absolute;
  top: 15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:after {
  position: absolute;
  top: 15px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content.hoverable {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content.hoverable:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li:not(:last-child):after {
  width: 2px;
  background-color: #9e9e9e;
  top: 32px;
  height: 100%;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-images li a .circle {
  font-size: 1rem;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-images li:not(:last-child):after {
  background-color: #26c6da;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-basic li a, .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li a, .timeline-main .stepper.stepper-vertical.timeline.timeline-animated li a {
    left: 8%;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-basic li a .circle, .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li a .circle, .timeline-main .stepper.stepper-vertical.timeline.timeline-animated li a .circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 0.9em;
    top: 26px;
    margin-left: -35px;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-basic li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline.timeline-animated li:not(:last-child):after {
    left: 9%;
    height: 100%;
    top: 55px;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li a, .timeline-main .stepper.stepper-vertical.timeline.timeline-images li a {
    left: 10%;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li a .circle, .timeline-main .stepper.stepper-vertical.timeline.timeline-images li a .circle {
    top: 18px;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline.timeline-images li:not(:last-child):after {
    left: 10%;
  }
}
.timeline-main .timeline-light {
  list-style: none;
  padding-left: 1em;
  line-height: 1.5em;
  font-size: 20px;
}
.timeline-main .timeline-light .timeline-light-item {
  position: relative;
}
.timeline-main .timeline-light .timeline-light-item .timeline-light-item-tail {
  position: absolute;
  border-left: 2px solid #e8e8e8;
  height: 100%;
  width: 0;
  bottom: 0;
  left: 14px;
}
.timeline-main .timeline-light .timeline-light-item .timeline-light-item-head {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 15px;
  bottom: 4px;
  transform: translate(-50%, 0);
  border: 2px solid #1890ff;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
}
.timeline-main .timeline-light .timeline-light-item .timeline-light-item-content {
  margin-left: 30px;
  padding-top: 0.2em;
  line-height: 3em;
  height: 2em;
}
.timeline-main .timeline-light .timeline-light-item-primary .timeline-light-item-head {
  border-color: #4285f4;
}
.timeline-main .timeline-light .timeline-light-item-danger .timeline-light-item-head {
  border-color: #ff3547;
}
.timeline-main .timeline-light .timeline-light-item-warning .timeline-light-item-head {
  border-color: #ffbb33;
}
.timeline-main .timeline-light .timeline-light-item-success .timeline-light-item-head {
  border-color: #00c851;
}
.timeline-main .timeline-light .timeline-light-item-info .timeline-light-item-head {
  border-color: #33b5e5;
}
.timeline-main .timeline-light .timeline-light-item-default .timeline-light-item-head {
  border-color: #2bbbad;
}
.timeline-main .timeline-light .timeline-light-item-secondary .timeline-light-item-head {
  border-color: #aa66cc;
}
.timeline-main .timeline-light .timeline-light-item-elegant .timeline-light-item-head {
  border-color: #2e2e2e;
}
.timeline-main .timeline-light .timeline-light-item-unique .timeline-light-item-head {
  border-color: #880e4f;
}
.timeline-main .timeline-light .timeline-light-item-dark-green .timeline-light-item-head {
  border-color: #388e3c;
}
.timeline-main .timeline-light .timeline-light-item-mdb-color .timeline-light-item-head {
  border-color: #59698d;
}
.timeline-main .timeline-light .timeline-light-item-red .timeline-light-item-head {
  border-color: #d32f2f;
}
.timeline-main .timeline-light .timeline-light-item-pink .timeline-light-item-head {
  border-color: #ec407a;
}
.timeline-main .timeline-light .timeline-light-item-purple .timeline-light-item-head {
  border-color: #8e24aa;
}
.timeline-main .timeline-light .timeline-light-item-deep-purple .timeline-light-item-head {
  border-color: #512da8;
}
.timeline-main .timeline-light .timeline-light-item-indigo .timeline-light-item-head {
  border-color: #3f51b5;
}
.timeline-main .timeline-light .timeline-light-item-blue .timeline-light-item-head {
  border-color: #1976d2;
}
.timeline-main .timeline-light .timeline-light-item-light-blue .timeline-light-item-head {
  border-color: #82b1ff;
}
.timeline-main .timeline-light .timeline-light-item-cyan .timeline-light-item-head {
  border-color: #00bcd4;
}
.timeline-main .timeline-light .timeline-light-item-teal .timeline-light-item-head {
  border-color: #00796b;
}
.timeline-main .timeline-light .timeline-light-item-green .timeline-light-item-head {
  border-color: #388e3c;
}
.timeline-main .timeline-light .timeline-light-item-light-green .timeline-light-item-head {
  border-color: #8bc34a;
}
.timeline-main .timeline-light .timeline-light-item-lime .timeline-light-item-head {
  border-color: #afb42b;
}
.timeline-main .timeline-light .timeline-light-item-yellow .timeline-light-item-head {
  border-color: #fbc02d;
}
.timeline-main .timeline-light .timeline-light-item-amber .timeline-light-item-head {
  border-color: #ffa000;
}
.timeline-main .timeline-light .timeline-light-item-orange .timeline-light-item-head {
  border-color: #f57c00;
}
.timeline-main .timeline-light .timeline-light-item-deep-orange .timeline-light-item-head {
  border-color: #ff7043;
}
.timeline-main .timeline-light .timeline-light-item-brown .timeline-light-item-head {
  border-color: #795548;
}
.timeline-main .timeline-light .timeline-light-item-grey .timeline-light-item-head {
  border-color: #616161;
}
.timeline-main .timeline-light .timeline-light-item-blue-grey .timeline-light-item-head {
  border-color: #78909c;
}
.timeline-main .timeline-light .timeline-light-item-dark .timeline-light-item-head {
  border-color: #212121;
}
.timeline-main .timeline-light .timeline-light-item-light .timeline-light-item-head {
  border-color: #e0e0e0;
}
.timeline-main .timeline-light .timeline-light-item-white .timeline-light-item-head {
  border-color: #fff;
}
.timeline-main .timeline-light .timeline-light-item-black .timeline-light-item-head {
  border-color: #000;
}
.timeline-main .timeline-light .timeline-light-item-loading .timeline-light-item-head {
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  animation: tl-v2-loadingCircle 1s 1s infinite linear;
}
.timeline-main .timeline-light .timeline-light-item-icon .timeline-light-item-head {
  border: none;
  width: 20px;
  height: 20px;
  background: transparent;
}
.timeline-main .timeline-light .timeline-light-item-icon .timeline-light-item-tail {
  height: 100%;
  bottom: 15px;
  left: 14px;
}
.timeline-main .timeline-light .timeline-light-item-icon .timeline-light-item-head {
  font-size: 20px;
  top: 45%;
}
.timeline-main .timeline-light .timeline-light-item-icon .timeline-light-item-head i {
  border-radius: 50%;
  background-color: #fff;
}
.timeline-main .timeline-light .timeline-light-item-dashed .timeline-light-item-tail {
  border-left-style: dashed;
}
.timeline-main .timeline-light li:first-child.timeline-light-item .timeline-light-item-tail {
  height: 0;
}
@keyframes tl-v2-loadingCircle {
  0% {
    transform: translate(-50%, 0) rotate(0deg);
  }
  100% {
    transform: translate(-50%, 0) rotate(360deg);
  }
}

.timeline-main .stepper.timeline-images li a {
  padding: 0px;
  left: 50%;
}
.timeline-main .stepper.timeline-images li a .circle {
  margin-top: 0.9rem;
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 1em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -12px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.timeline-images li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.timeline-images li .step-content.hoverable {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.timeline-main .stepper.timeline-images li .step-content.hoverable:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.timeline-main .stepper.timeline-images li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.timeline-images li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.stepper-vertical.timeline-images li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline-simple li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 2px;
  left: 50%;
  top: 54px;
  height: 100%;
  margin-left: -1.5px;
}
.timeline-main .stepper.stepper-vertical.timeline-images li:not(:last-child):after {
  background-color: #26c6da;
}
.timeline-main .stepper.stepper-vertical.timeline-simple li:not(:last-child):after {
  background-color: #9e9e9e;
}
.timeline-main .stepper.timeline-simple li a {
  padding: 0px;
  left: 50%;
}
.timeline-main .stepper.timeline-simple li a .circle {
  margin-top: 0.9rem;
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -12px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.timeline-simple li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.timeline-simple li .step-content:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline-simple li .step-content:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline-simple li .step-content.hoverable {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.timeline-main .stepper.timeline-simple li .step-content.hoverable:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.timeline-main .stepper.timeline-simple li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.timeline-simple li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.timeline-simple li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.timeline-simple li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.timeline li a {
  padding: 0px 24px;
  left: 50%;
}
.timeline-main .stepper.timeline li a .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -50px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.timeline li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.timeline li .step-content:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline li .step-content:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.timeline li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.timeline li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.timeline li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 3px;
  background-color: #e0e0e0;
  left: 50%;
  top: 65px;
  margin-left: -1.5px;
}
.timeline-main .stepper.colorful-timeline li a {
  padding: 0px 24px;
  left: 50%;
}
.timeline-main .stepper.colorful-timeline li a .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -50px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.colorful-timeline li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.colorful-timeline li .step-content:before {
  position: absolute;
  top: 1rem;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #d32f2f;
  border-right: 0 solid #d32f2f;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline-main .stepper.colorful-timeline li .step-content:after {
  position: absolute;
  top: 1rem;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #d32f2f;
  border-right: 0 solid #d32f2f;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline-main .stepper.colorful-timeline li .step-content .timeline-header {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.timeline-main .stepper.colorful-timeline li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.colorful-timeline li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.colorful-timeline li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.colorful-timeline li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical.colorful-timeline li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 3px;
  background-color: #d32f2f;
  left: 50%;
  top: 65px;
  margin-left: -1.5px;
}
.timeline-main .stepper.timeline-animated .more-padding {
  padding-right: 100px;
  padding-left: 100px;
}
.timeline-main .stepper.timeline-animated li {
  transition: all 0.7s ease-in-out;
}
.timeline-main .stepper.timeline-animated li a {
  padding: 0px 24px;
  left: 50%;
}
.timeline-main .stepper.timeline-animated li a .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -50px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.timeline-animated li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.timeline-animated li .step-content:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline-animated li .step-content:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline-animated li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.timeline-animated li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.timeline-animated li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.timeline-animated li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 3px;
  background-color: #e0e0e0;
  left: 50%;
  top: 65px;
  margin-left: -1.5px;
}

@media (max-width: 1025px) {
  .timeline-main .stepper.timeline-images li, .timeline-main .stepper.timeline li, .timeline-main .stepper.colorful-timeline li, .timeline-main .stepper.timeline-animated li, .timeline-main .stepper.timeline-simple li {
    align-items: flex-end;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.timeline-images li a, .timeline-main .stepper.timeline-simple li a, .timeline-main .stepper.timeline li a, .timeline-main .stepper.colorful-timeline li a, .timeline-main .stepper.timeline-animated li a {
    left: 6%;
  }
  .timeline-main .stepper.timeline-images li .step-content, .timeline-main .stepper.timeline-simple li .step-content, .timeline-main .stepper.timeline li .step-content, .timeline-main .stepper.colorful-timeline li .step-content, .timeline-main .stepper.timeline-animated li .step-content {
    width: 80%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
  .timeline-main .stepper.timeline-simple li .step-content:before, .timeline-main .stepper.timeline li .step-content:before, .timeline-main .stepper.colorful-timeline li .step-content:before, .timeline-main .stepper.timeline-animated li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  .timeline-main .stepper.timeline-simple li .step-content:after, .timeline-main .stepper.timeline li .step-content:after, .timeline-main .stepper.colorful-timeline li .step-content:after, .timeline-main .stepper.timeline-animated li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  .timeline-main .stepper.stepper-vertical.timeline-simple li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline-images li:not(:last-child):after, .timeline-main .stepper.colorful-timeline li a, .timeline-main .stepper.stepper-vertical.colorful-timeline li:not(:last-child):after, .timeline-main .stepper.timeline-animated li a, .timeline-main .stepper.stepper-vertical li:not(:last-child):after {
    left: 6%;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.timeline-simple li .step-content:before, .timeline-main .stepper.timeline-animated li .step-content:before, .timeline-main .stepper.timeline li .step-content:before, .timeline-main .stepper.colorful-timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  .timeline-main .stepper.timeline-simple li .step-content:after, .timeline-main .stepper.timeline-animated li .step-content:after, .timeline-main .stepper.timeline li .step-content:after, .timeline-main .stepper.colorful-timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  .timeline-main .stepper.timeline-images li a, .timeline-main .stepper.timeline li a, .timeline-main .stepper.timeline-simple li a, .timeline-main .stepper.timeline-animated li a, .timeline-main .stepper.colorful-timeline li a {
    left: 6%;
  }
  .timeline-main .stepper.timeline-images li .step-content, .timeline-main .stepper.timeline li .step-content, .timeline-main .stepper.timeline-simple li .step-content, .timeline-main .stepper.timeline-animated li .step-content, .timeline-main .stepper.colorful-timeline li .step-content {
    width: 85%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
  .timeline-main .stepper.stepper-vertical.timeline-images li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline-simple li:not(:last-child):after, .timeline-main .stepper.stepper-vertical li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.colorful-timeline li:not(:last-child):after {
    left: 6%;
  }
}
body {
  background-color: #eee;
}

.card.card-cascade .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}

.card.card-cascade h3,
.card.card-cascade h4 {
  margin-bottom: 0px;
}

.login-card {
  background-color: rgba(229, 228, 255, 0.2);
}

.blue-gradient {
  background: -webkit-linear-gradient(50deg, #45cafc, #303f9f);
  background: linear-gradient(40deg, #45cafc, #4285f4);
}

.datepickers .md-form,
.inputs .md-form {
  margin-top: 0 !important;
}

.modal-full-height.modal-bottom > .modal-content {
  position: absolute !important;
  bottom: 0;
}

.view .full-bg-img.mask {
  overflow-y: scroll;
}